import React, { useContext } from "react";
import styled from "styled-components";
import { PapAppContext } from "../AppContext";
import {
  AgeInput,
  HPVInput,
  CytologyInput,
  PregnancyInput,
} from "../Components/HomeScreenInputs";
import { SubmitButton } from "../Screens/RecommendationScreens/Components";
import { RecommendationTitle } from "./RecommendationScreens/Components";
import { useHistory } from "react-router-dom";
import { GetInitialRecommendation } from "./GetInitialRecommendation";
import * as Constants from "../constants";
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const ManagementHomeScreen = () => {
  const {
    state: { age, hpvStatus, cytologyResult, isPregnant },
    dispatch,
  } = useContext(PapAppContext);
  const history = useHistory();

  const onSubmit = () => {
    if (
      age === Constants.twentyOne_Four &&
      hpvStatus !== Constants.HPV_NotIndicated
    )
      alert(
        `HPV testing not indicated in women age ${Constants.twentyOne_Four}`
      );

    let papStackEntry = {
      hpvStatus,
      cytologyResult,
    };
    dispatch({ type: Constants.addToPapStack, payload: papStackEntry });

    dispatch({
      type: Constants.addToScreenTypeArray,
      payload: Constants.HomeScreen,
    });

    let recommendation = GetInitialRecommendation(
      cytologyResult,
      hpvStatus,
      age,
      isPregnant
    );

    history.push(`${Constants.MANAGEMENT}${recommendation.toLowerCase()}`);
  };

  const validateForm = () => {
    return age && hpvStatus && cytologyResult ? true : false;
  };
  return (
    <StyledContainer>
      <RecommendationTitle>
        <StyledSpan>Initial Cervical Cancer Screening</StyledSpan>
      </RecommendationTitle>
      <AgeInput></AgeInput>
      <HPVInput></HPVInput>
      <CytologyInput></CytologyInput>
      <PregnancyInput></PregnancyInput>

      <SubmitButton
        disabled={!validateForm()}
        onClick={(e) => onSubmit(e)}
        w="auto"
        heigh="10vh"
      >
        Submit
      </SubmitButton>
    </StyledContainer>
  );
};

const StyledSpan = styled.span`
  font-size: 3vh;
  align-self: center;
  padding: 1vh;
`;
