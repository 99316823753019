import React, { useContext } from "react";
import styled from "styled-components";
import { PapAppContext } from "../AppContext";
import { StyledButton } from "./StyledButton";
import * as Constants from "../constants";

const StyledContainer = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledH2 = styled.h2`
  width: 50%;
  height: auto;
`;

////////////
export const AgeInput = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const activateModal = () => {
    dispatch({ type: Constants.showHideAgeModal });
  };
  return (
    <StyledContainer>
      <StyledH2>Age</StyledH2>
      <StyledButton onClick={() => activateModal()} w="25%" h="50%">
        {state.age}
      </StyledButton>
    </StyledContainer>
  );
};

/////////////////////////

export const HPVInput = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const activateModal = () => {
    dispatch({ type: Constants.showHideHPVModal });
  };
  return (
    <StyledContainer>
      <StyledH2>HPV Status</StyledH2>
      <StyledButton onClick={() => activateModal()} w="25%" h="50%">
        {state.hpvStatus}
      </StyledButton>
    </StyledContainer>
  );
};

////////////////////////////////
export const CytologyInput = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const activateModal = () => {
    dispatch({ type: Constants.showHideCytologyModal });
  };

  return (
    <StyledContainer>
      <StyledH2>Cytology</StyledH2>
      <StyledButton onClick={() => activateModal()} w="25%" h="50%">
        {state.cytologyResult}
      </StyledButton>
    </StyledContainer>
  );
};

//////////////////////////////////

export const PregnancyInput = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const activateModal = () => {
    dispatch({ type: Constants.showHidePregnancyModal });
  };

  return (
    <StyledContainer>
      <StyledH2>Pregnant</StyledH2>
      <StyledButton onClick={() => activateModal()} w="25%" h="50%">
        {state.isPregnant ? "Yes" : "No"}
      </StyledButton>
    </StyledContainer>
  );
};
