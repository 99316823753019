import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const LoaderButton = (props) => {
  const { disabled, children, isLoading, onClick, ...rest } = props;
  return (
    <LoginButton {...rest} disabled={disabled || isLoading} onClick={onClick}>
      {isLoading && (
        <FontAwesomeIcon
          className="spinning"
          icon={faRedoAlt}
        ></FontAwesomeIcon>
      )}
      <span> {children}</span>
    </LoginButton>
  );
};

const spin = keyframes`
  from {
      transform: scale(1) rotate(0deg); 
  }
  to {
      transform: scale(1) rotate(360deg); 
  }
`;

const LoginButton = styled.button`
  width: ${(props) => props.width || "90%"};
  height: ${(props) => props.height || "15%"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  align-items: center;
  background: ${(props) => props.background || "#02617D"};
  border: none;
  box-shadow: 0 8px 6px -6px black;
  font-size: 1.2vh;
  span {
    font-size: 15px;
    color: white;
  }
  .spinning {
    height: 15px;
    width: 15px;
    color: white;
    animation: ${spin} 1s infinite linear;
  }
`;

export default LoaderButton;
