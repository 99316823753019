import React, { Suspense } from "react";

import { animated, useTransition } from "react-spring";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { ManagementHomeScreen } from "../Screens/ManagementHomeScreen";
import {
  AgeModal,
  HPVModal,
  CytologyModal,
  PregnancyModal,
} from "../Modals/HomeScreenModals";

import { ROUTE_PATHS_MANAGEMENT } from "../constants";

const Cyto_Colpo_6_24_OR_Excision = React.lazy(() =>
  import("../Modals/Cyto_Colpo_6_24_OR_Excision")
);
const Cotest_12_24_OR_Excision = React.lazy(() =>
  import("../Modals/Cotest_12_24_OR_Excision")
);
const Cotest_12_24 = React.lazy(() =>
  import("../Screens/RecommendationScreens/Cotest_12_24")
);
const Cotest_12 = React.lazy(() =>
  import("../Screens/RecommendationScreens/Cotest_12")
);
const Cyto_12 = React.lazy(() =>
  import("../Screens/RecommendationScreens/Cyto_12")
);
const ECC_Cyto = React.lazy(() =>
  import("../Screens/RecommendationScreens/ECC_Cyto")
);
const Excision = React.lazy(() =>
  import("../Screens/RecommendationScreens/Excision")
);
const GYN_ONC = React.lazy(() =>
  import("../Screens/RecommendationScreens/Gyn_Onc")
);
const Routine = React.lazy(() =>
  import("../Screens/RecommendationScreens/Routine")
);
const Colpo_Cotest_6_Hyst = React.lazy(() =>
  import("../Screens/RecommendationScreens/Colpo_Cotest_6_Hyst")
);
const Excision_OR_Colpo = React.lazy(() =>
  import("../Modals/Excision_OR_Colpo")
);
const RepeatExcision_Vs_Colpo_Cotest_Hyst = React.lazy(() =>
  import("../Modals/RepeatExcision_Vs_Colpo_Cotest_Hyst")
);
const RepeatExcision_Vs_ECC_Cyto = React.lazy(() =>
  import("../Modals/RepeatExcision_Vs_ECC_Cyto")
);
const Colposcopy = React.lazy(() =>
  import("../Screens/RecommendationScreens/Colposcopy")
);
const Colpo_Pap_6_For_24 = React.lazy(() =>
  import("../Screens/RecommendationScreens/Colpo_Pap_6_For_24")
);
const Colposcopy_Pregnant = React.lazy(() =>
  import("../Screens/RecommendationScreens/Colposcopy_Pregnant")
);
const Colpo_Preg_High = React.lazy(() =>
  import("../Screens/RecommendationScreens/Colpo_Preg_High")
);
const Colpo_Preg_Low = React.lazy(() =>
  import("../Screens/RecommendationScreens/Colpo_Preg_Low")
);

export const Management = () => {
  const { path } = useRouteMatch();

  console.log(` path: ${path}`);

  return (
    <Router>
      <Routes></Routes>
    </Router>
  );
};

const Routes = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  const transitions = useTransition(location, (location) => location.pathname, {
    from: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      transform: "translateX(-100vw)",
    },
    enter: { transform: "translateX(0)" },
    leave: { transform: "translateX(100vw)" },
  });

  return transitions.map(({ item, key, props }) => (
    <animated.div key={key} style={props}>
      <Switch location={item}>
        <Route exact path={path}>
          <ManagementHomeScreen></ManagementHomeScreen>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.HomeScreen}>
          <ManagementHomeScreen></ManagementHomeScreen>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Colposcopy}>
          <Suspense fallback={<div>Loading...</div>}>
            <Colposcopy></Colposcopy>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Colpo_Pap_6_For_24}>
          <Suspense fallback={<div>Loading...</div>}>
            <Colpo_Pap_6_For_24></Colpo_Pap_6_For_24>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Cotest_12_24}>
          <Suspense fallback={<div>Loading...</div>}>
            <Cotest_12_24></Cotest_12_24>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Cotest_12}>
          <Suspense fallback={<div>Loading...</div>}>
            <Cotest_12></Cotest_12>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Cytology_12}>
          <Suspense fallback={<div>Loading...</div>}>
            <Cyto_12></Cyto_12>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.ECC_Cyto}>
          <Suspense fallback={<div>Loading...</div>}>
            <ECC_Cyto></ECC_Cyto>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Excision}>
          <Suspense fallback={<div>Loading...</div>}>
            <Excision></Excision>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Gyn_Onc}>
          <Suspense fallback={<div>Loading...</div>}>
            <GYN_ONC></GYN_ONC>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Routine}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routine></Routine>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Cyto_Colpo_6_24_OR_Excision}>
          <Suspense fallback={<div>Loading...</div>}>
            <Cyto_Colpo_6_24_OR_Excision></Cyto_Colpo_6_24_OR_Excision>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Cotest_12_24_OR_Excision}>
          <Suspense fallback={<div>Loading...</div>}>
            <Cotest_12_24_OR_Excision></Cotest_12_24_OR_Excision>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Excision_Or_Colpo}>
          <Suspense fallback={<div>Loading...</div>}>
            <Excision_OR_Colpo></Excision_OR_Colpo>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Colpo_Cotest_6_Hyst}>
          <Suspense fallback={<div>Loading...</div>}>
            <Colpo_Cotest_6_Hyst></Colpo_Cotest_6_Hyst>
          </Suspense>
        </Route>
        <Route
          exact
          path={ROUTE_PATHS_MANAGEMENT.RepeatExcision_Vs_Colpo_Cotest_Hyst}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <RepeatExcision_Vs_Colpo_Cotest_Hyst></RepeatExcision_Vs_Colpo_Cotest_Hyst>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Colposcopy_Pregnant}>
          <Suspense fallback={<div>Loading...</div>}>
            <Colposcopy_Pregnant></Colposcopy_Pregnant>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Colpo_Preg_Low}>
          <Suspense fallback={<div>Loading...</div>}>
            <Colpo_Preg_Low></Colpo_Preg_Low>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.Colpo_Preg_High}>
          <Suspense fallback={<div>Loading...</div>}>
            <Colpo_Preg_High></Colpo_Preg_High>
          </Suspense>
        </Route>
        <Route exact path={ROUTE_PATHS_MANAGEMENT.RepeatExcision_Vs_ECC_Cyto}>
          <Suspense fallback={<div>Loading...</div>}>
            <RepeatExcision_Vs_ECC_Cyto></RepeatExcision_Vs_ECC_Cyto>
          </Suspense>
        </Route>
      </Switch>
      <AgeModal></AgeModal>
      <HPVModal></HPVModal>
      <CytologyModal></CytologyModal>
      <PregnancyModal></PregnancyModal>
    </animated.div>
  ));
};
