import React, { useState, useEffect, useContext } from "react";
import { useSpring, animated } from "react-spring";
import { Link, useHistory } from "react-router-dom";
import { PapAppContext } from "./AppContext";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { Header } from "./Components/Header";
import { KPLogo } from "./Images/KPLogo";
import { InputContainer } from "./Components/InputContainer";
import { MenuButton } from "./Components/MenuButton";
import * as Constants from "./constants";

import { Routes } from "./Routes";
import LogInScreen from "./Screens/LogInScreen";

const KPapDiv = styled.div`
  color: ${Constants.KAISER_BLUE};
  font-size: 4vh;
`;

const StyledMain = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #e5e4e2;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  align-items: center;
`;

const LinkDiv = styled.div`
  width: 75vw;
  height: 15vh;
  text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.5);
  background: #fafafa;
  color: ${Constants.KAISER_BLUE};
  border-radius: 5px;

  font-size: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const App = () => {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  const {
    state: { isAuthenticated, isAuthenticating },
    dispatch,
  } = useContext(PapAppContext);
  console.log(isAuthenticated, isAuthenticating);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      await Auth.currentSession();
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: true });
    } catch (e) {
      console.log(e);
    }
    dispatch({ type: Constants.FINISH_AUTHENTICATING });
  };

  const props = useSpring({
    opacity: showMenu ? 1 : 0,
    transform: showMenu ? "translateY(0%)" : "translateY(30%)",

    position: "fixed",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    top: "9vh",
    bottom: "0vh",
    right: "0vh",
    left: "0vh",

    background: `linear-gradient(${Constants.KAISER_BLUE}, ${Constants.LIGHT_GRAY})`,
    zIndex: "2000",
  });

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const logout = async () => {
    dispatch({ type: Constants.SET_IS_LOADING, payload: true });
    try {
      await Auth.signOut();
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: false });
      history.push("/login");
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
      alert(e);
    }
  };
  return (
    !isAuthenticating && (
      <div style={{ width: "100vw", height: "100vh" }}>
        {!isAuthenticated ? (
          <LogInScreen></LogInScreen>
        ) : (
          <div>
            {showMenu && (
              <animated.div style={props}>
                <Link to="/screening" onClick={toggleMenu}>
                  <LinkDiv>Screening</LinkDiv>
                </Link>
                <Link to="/management" onClick={toggleMenu}>
                  <LinkDiv>Management</LinkDiv>
                </Link>
                <Link to="/algorithms" onClick={toggleMenu}>
                  <LinkDiv>Algorithms</LinkDiv>
                </Link>
                <LinkDiv onClick={logout}>Sign Out</LinkDiv>
              </animated.div>
            )}

            <StyledMain>
              <Header>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    gap: "1em",
                    marginLeft: "5px",
                  }}
                >
                  <KPLogo size="5vh"></KPLogo>
                  <KPapDiv>KPap</KPapDiv>
                </div>
                <MenuButton
                  showMenu={showMenu}
                  toggleMenu={toggleMenu}
                ></MenuButton>
              </Header>
              <StyledDiv>
                <InputContainer>
                  <Routes></Routes>
                </InputContainer>
              </StyledDiv>
            </StyledMain>
          </div>
        )}
      </div>
    )
  );
};
