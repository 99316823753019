import styled from "styled-components";
export const StyledButton = styled.button`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin: ${(props) => props.margin || "0px"};
  box-shadow: 0 3px 6px -6px black;
  cursor: pointer;
  border: none;
  text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.5);
  border-radius: 7px;
  background-color: ${(props) => props.b_color || "#fafafa"};
`;
