import React, { useState, useRef, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import KPLogoAnimation from "../Images/KPLogoAnimation";
import styled, { keyframes } from "styled-components";
import { PapAppContext } from "../AppContext";
import * as Constants from "../constants";
import LoaderButton from "./LoaderButton";

const LogInScreen = () => {
  const [isLoginSelected, setIsLoginSelected] = useState(true);
  const emailInput = useRef(null);
  const {
    state: { isLoading, isAuthenticated },
    dispatch,
  } = useContext(PapAppContext);

  const history = useHistory();
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const [newUser, setNewUser] = useState(null);

  const [signUpFields, setSignUpFields] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  const validateLoginForm = () => {
    return emailLogin.length > 0 && passwordLogin.length > 0;
  };

  const validateSignUpForm = () => {
    return (
      signUpFields.email.length > 0 &&
      signUpFields.password.length > 0 &&
      signUpFields.password === signUpFields.confirmPassword
    );
  };

  const validateConfirmationCode = () => {
    return signUpFields.confirmationCode.length > 0;
  };

  const handleLogin = async () => {
    dispatch({ type: Constants.SET_IS_LOADING, payload: true });
    try {
      await Auth.signIn(emailLogin, passwordLogin);
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: true });
      history.push("/management");
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    } catch (e) {
      console.log(e);
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    }
  };

  const handleSignUp = async () => {
    dispatch({ type: Constants.SET_IS_LOADING, payload: true });

    try {
      const newUser = await Auth.signUp({
        username: signUpFields.email,
        password: signUpFields.password,
      });
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
      setNewUser(newUser);
    } catch (e) {
      console.log(e);
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    }
  };

  const handleConfirmationSubmit = async () => {
    dispatch({ type: Constants.SET_IS_LOADING, payload: true });

    try {
      await Auth.confirmSignUp(
        signUpFields.email,
        signUpFields.confirmationCode
      );
      await Auth.signIn(signUpFields.email, signUpFields.password);
      dispatch({ type: Constants.USER_HAS_AUTHENTICATED, payload: true });
      history.push("/management");
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    } catch (e) {
      console.log(e);
      dispatch({ type: Constants.SET_IS_LOADING, payload: false });
    }
  };

  const renderConfirmationForm = () => {
    return (
      <LoginFormContainer>
        <LoginSpanText>Enter Confirmation Code</LoginSpanText>
        <TextInput
          placeholder="Confirmation Code"
          value={signUpFields.confirmationCode}
          onChange={(e) =>
            setSignUpFields({
              ...signUpFields,
              confirmationCode: e.target.value,
            })
          }
        ></TextInput>
        <LoaderButton
          isLoading={isLoading}
          disabled={!validateConfirmationCode()}
          onClick={() => handleConfirmationSubmit()}
        >
          Submit
        </LoaderButton>
      </LoginFormContainer>
    );
  };

  const renderSignUpForm = () => {
    console.log(`email ${signUpFields.email}`);
    return (
      <LoginFormContainer>
        <LoginSpanText>Sign-up for an account</LoginSpanText>
        <TextInput
          placeholder="Email"
          value={signUpFields.email}
          onChange={(e) =>
            setSignUpFields({ ...signUpFields, email: e.target.value })
          }
        ></TextInput>
        <TextInput
          placeholder="Password"
          type="password"
          value={signUpFields.password}
          onChange={(e) =>
            setSignUpFields({ ...signUpFields, password: e.target.value })
          }
        ></TextInput>
        <TextInput
          placeholder="Confirm Password"
          type="password"
          value={signUpFields.confirmPassword}
          onChange={(e) =>
            setSignUpFields({
              ...signUpFields,
              confirmPassword: e.target.value,
            })
          }
        ></TextInput>
        <LoaderButton
          isLoading={isLoading}
          disabled={!validateSignUpForm()}
          onClick={() => handleSignUp()}
        >
          Sign-up
        </LoaderButton>
      </LoginFormContainer>
    );
  };

  const toggleLogin = (isSelected) => {
    if (!isSelected) {
      setIsLoginSelected((prev) => !prev);
      return;
    } else {
      return;
    }
  };

  const toggleSignup = (isSelected) => {
    if (isSelected) {
      setIsLoginSelected((prev) => !prev);
      return;
    } else {
      return;
    }
  };

  return (
    <div style={styles.loginContainerPage}>
      <KPLogoAnimation size="30vh"></KPLogoAnimation>
      <ButtonContainer>
        <LogInSignInButton
          opacity={isLoginSelected ? 1 : 0.5}
          onClick={() => toggleLogin(isLoginSelected)}
        >
          Log-In
        </LogInSignInButton>
        <LogInSignInButton
          opacity={!isLoginSelected ? 1 : 0.5}
          onClick={() => toggleSignup(isLoginSelected)}
        >
          Sign-Up
        </LogInSignInButton>
      </ButtonContainer>
      <FlippableContainer>
        <FlippableCard isLoginSelected={isLoginSelected}>
          <FrontCard>
            <LoginFormContainer>
              <LoginSpanText>Log-in to your account</LoginSpanText>
              <TextInput
                ref={emailInput}
                placeholder="Email"
                value={emailLogin}
                onChange={(e) => setEmailLogin(e.target.value)}
              ></TextInput>
              <TextInput
                placeholder="Password"
                type="password"
                value={passwordLogin}
                onChange={(e) => setPasswordLogin(e.target.value)}
              ></TextInput>
              <LoaderButton
                isLoading={isLoading}
                disabled={!validateLoginForm()}
                onClick={() => handleLogin()}
              >
                Log-In
              </LoaderButton>
            </LoginFormContainer>
          </FrontCard>
          <BackCard>
            {newUser === null ? renderSignUpForm() : renderConfirmationForm()}
          </BackCard>
        </FlippableCard>
      </FlippableContainer>
    </div>
  );
};

const styles = {
  loginContainerPage: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const ButtonContainer = styled.div`
  width: 80%;
  height: 5%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const LogInSignInButton = styled.div`
  background: ${(props) => props.background || "#02617d"};
  opacity: ${(props) => props.opacity};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
`;

const FlippableContainer = styled.div`
  width: 80%;
  height: 50%;
  background: transparent;
  perspective: 1000px;
`;

const FlippableCard = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;

  transform: ${(props) =>
    props.isLoginSelected ? "rotateY(360deg)" : "rotateY(180deg)"};
`;

const FrontCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

const BackCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginFormContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: ${Constants.LIGHT_GRAY};

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  width: 30%;
  height: 30%;
  top: 80%;
  left: 1%;

  animation-name: delay-login-form;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes delay-login-form {
    to {
      opacity: 100;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 50%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LoginSpanText = styled.span`
  font-size: 3vh;
  font-style: bold;

  margin-bottom: 2%;
  color: white;
  width: 90%;
  height: 10%;
`;

const TextInput = styled.input.attrs((props) => ({
  type: props.type || "text",
  placeholder: props.placeholder || null,
}))`
  width: 90%;
  height: 10%;
  margin-bottom: 5%;

  border-radius: 5px;
  box-shadow: 0 8px 6px -6px black;
  font-size: 1.5vh;
`;

export default LogInScreen;
