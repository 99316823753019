import React, { Suspense } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Management } from "./Tabs/Management";
import LogInScreen from "./Screens/LogInScreen";
import SignUpScreen from "./Screens/SignUpScreen";

const Algorithms = React.lazy(() => import("./Tabs/Algorithms"));
const AlgoImage = React.lazy(() =>
  import("./Screens/AlgoImageScreens/AlgoImage")
);
const Screening = React.lazy(() => import("./Tabs/Screening"));

export const Routes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path="/management">
        <Management></Management>
      </Route>
      <Route exact path="/algorithms">
        <Suspense fallback={<div>Loading...</div>}>
          <Algorithms></Algorithms>
        </Suspense>
      </Route>

      <Route exact path={path}>
        <Management></Management>
      </Route>

      <Route exact path="/login">
        <LogInScreen></LogInScreen>
      </Route>

      <Route exact path="/signup">
        <SignUpScreen></SignUpScreen>
      </Route>

      <Route exact path="/screening">
        <Suspense fallback={<div>Loading...</div>}>
          <Screening></Screening>
        </Suspense>
      </Route>

      <Route exact path="/algorithms/:name">
        <Suspense fallback={<div>Loading...</div>}>
          <AlgoImage></AlgoImage>
        </Suspense>
      </Route>
    </Switch>
  );
};
