import React from "react";
import styled, { keyframes } from "styled-components";
import "./imageStyleSheet.css";

const text = keyframes`
 0% {
   opacity: 0; 
 }
  100% {
    opacity: 100; 
  }
`;

const StyledSVG = styled.svg.attrs((props) => ({
  viewBox: "-10 -10 240 240",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
}))`
  width: ${(props) => props.width || "240px"};
  height: ${(props) => props.height || "240px"};
  fill: none;
`;

const StyledPath = styled.path.attrs((props) => ({
  d: props.d,
  fill: "url(#my-gradient)",

  stroke: props.stroke || "black",
}))`
  stroke-width: 0.2%;
`;

const StyledText = styled.text.attrs((props) => ({
  x: "105",
  y: "200",
  textAnchor: "middle",
}))`
  font-size: 30px;
  fill: ${(props) => props.fill || "white"};
  opacity: 0;
  animation-name: ${text};
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export default function KPLogoAnimation({ size }) {
  return (
    <div>
      <StyledSVG width={size} height={size}>
        <defs>
          <filter id="shadow">
            <feGaussianBlur stdDeviation="2 2" result="shadow"></feGaussianBlur>
            <feOffset dx="2" dy="2"></feOffset>
          </filter>
          <linearGradient id="my-gradient" gradientTransform="rotate(90)">
            <stop className="first-stop" offset="0%" />
            <stop className="last-stop" offset="100%" />
          </linearGradient>
        </defs>

        <StyledPath d={pathInputs.path1}></StyledPath>
        <StyledPath d={pathInputs.path2}></StyledPath>
        <StyledPath d={pathInputs.path3}></StyledPath>
        <StyledPath d={pathInputs.path4}></StyledPath>
        <StyledPath d={pathInputs.path5}></StyledPath>
        <StyledPath d={pathInputs.path6}></StyledPath>
        <StyledPath d={pathInputs.path7}></StyledPath>
        <StyledPath d={pathInputs.path8}></StyledPath>
        <StyledPath d={pathInputs.path9}></StyledPath>
        <StyledPath d={pathInputs.path10}></StyledPath>
        <StyledPath d={pathInputs.path11}></StyledPath>
        <StyledPath d={pathInputs.path12}></StyledPath>
        <StyledPath d={pathInputs.path13}></StyledPath>
        <StyledPath d={pathInputs.path14}></StyledPath>
        <StyledPath d={pathInputs.path15}></StyledPath>
        <StyledPath d={pathInputs.path16}></StyledPath>
        <StyledPath d={pathInputs.path17}></StyledPath>
        <StyledPath d={pathInputs.path18}></StyledPath>
        <StyledText style={{ filter: "url(#shadow)" }} fill="black">
          K-Pap
        </StyledText>
        <StyledText> K-Pap</StyledText>
      </StyledSVG>
    </div>
  );
}

const pathInputs = {
  path1: "M0.158248 199.786H44.6574L0.158248 179.679V199.786Z",
  path2: "M45.871 186.524L0.158248 154.225V174.332L45.871 186.524Z",
  path3: "M50.5232 174.332L0.562786 122.781V149.519L50.5232 174.332Z",
  path4:
    "M58.4117 165.561L5.82178 86.631C0.370251 97.1024 -0.684805 103.959 0.360518 117.647L58.4117 165.561Z",
  path5:
    "M68.1206 157.647L30.9031 70.8021C18.9926 71.4176 14.208 74.6319 8.24901 84.2781L68.1206 157.647Z",
  path6:
    "M34.544 70.5881C50.9557 68.8549 57.9 73.3861 64.4798 90.2673C69.631 104.677 73.7842 127.273 77.9442 150.802L34.544 70.5881Z",
  path7:
    "M90.5725 149.733L86.5271 56.6845C72.2331 62.1764 67.1091 71.0536 68.7274 85.9893L90.5725 149.733Z",
  path8:
    "M89.7634 55.8288L102.102 148.235L114.642 55.8288C105.191 51.9215 99.7553 51.7426 89.7634 55.8288Z",
  path9:
    "M118.081 56.6845L113.833 149.733L135.274 86.4171C137.318 71.1745 133.158 64.8284 118.081 56.6845Z",
  path10:
    "M161.569 84.7059L124.554 152.941L134.465 101.818C140.7 88.9024 146.624 85.1414 161.569 84.7059Z",
  path11:
    "M166.019 83.8503L134.869 158.717L189.684 94.1176C182.698 87.1528 177.751 84.5999 166.019 83.8503Z",
  path12:
    "M192.516 97.3262L144.983 166.203L199.595 120.642C200.39 109.638 199.108 104.388 192.516 97.3262Z",
  path13: "M199.798 151.872L152.265 175.615L199.798 126.845V151.872Z",
  path14: "M200 176.471L158.13 186.096L200 157.433V176.471Z",
  path15: "M200 200H159.142L200 181.39V200Z",
  path16:
    "M35.2519 65.6684C46.7022 65.6684 55.9845 56.0438 55.9845 44.1711C55.9845 32.2985 46.7022 22.6738 35.2519 22.6738C23.8016 22.6738 14.5193 32.2985 14.5193 44.1711C14.5193 56.0438 23.8016 65.6684 35.2519 65.6684Z",
  path17:
    "M102.304 49.4118C115.039 49.4118 125.363 38.3506 125.363 24.7059C125.363 11.0612 115.039 0 102.304 0C89.5691 0 79.2454 11.0612 79.2454 24.7059C79.2454 38.3506 89.5691 49.4118 102.304 49.4118Z",
  path18:
    "M185.234 58.2888C185.234 70.1614 176.36 79.7861 165.412 79.7861C154.464 79.7861 145.59 70.1614 145.59 58.2888C145.59 46.4161 154.464 36.7914 165.412 36.7914C176.36 36.7914 185.234 46.4161 185.234 58.2888Z",
};
