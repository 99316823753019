import React from "react";

export const MenuButton = ({ toggleMenu, showMenu }) => {
  const changeButtonToActiveState = () => {
    toggleMenu();
  };

  return (
    <div
      className={showMenu ? `menuBtn active` : `menuBtn`}
      onClick={() => changeButtonToActiveState()}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
