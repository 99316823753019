import React, { createContext, useReducer } from "react";
import * as Constants from "./constants";

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  isSigningUp: false,
  isLoading: false,
  age: "",
  ageModalShowing: false,
  hpvStatus: "",
  hpvModalShowing: false,
  cytologyResult: "",
  pathologyResult: "",
  marginResult: "",
  eccResult: "",
  isPregnant: false,
  isExcision: false,
  papStack: [],
  pathologyStack: [],
  screenTypeArray: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case Constants.USER_HAS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    case Constants.FINISH_AUTHENTICATING:
      return { ...state, isAuthenticating: false };
    case Constants.START_AUTHENTICATING:
      return { ...state, isAuthenticating: true };
    case Constants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case Constants.setAge:
      return { ...state, age: action.payload };
    case Constants.showHideAgeModal:
      let isShowing = !state.ageModalShowing;
      return { ...state, ageModalShowing: isShowing };
    case Constants.setHPVStatus:
      return { ...state, hpvStatus: action.payload };
    case Constants.setExcision:
      let excisionStatus = state.isExcision;
      return { ...state, isExcision: !excisionStatus };
    case Constants.showHideHPVModal:
      let isHpvModalShowing = !state.hpvModalShowing;
      return { ...state, hpvModalShowing: isHpvModalShowing };
    case Constants.setCytologyResult:
      return { ...state, cytologyResult: action.payload };
    case Constants.clearCytologyResult:
      return { ...state, cytologyResult: "" };
    case Constants.setPathologyResult:
      return { ...state, pathologyResult: action.payload };
    case Constants.setEccResult:
      return { ...state, eccResult: action.payload };
    case Constants.setMarginResult:
      return { ...state, marginResult: action.payload };
    case Constants.showHideCytologyModal:
      let isShowingCytoModal = !state.cytologyModalShowing;
      return { ...state, cytologyModalShowing: isShowingCytoModal };
    case Constants.setPregnancyStatus:
      let isCurrentlyPregnant = !state.isPregnant;
      return { ...state, isPregnant: isCurrentlyPregnant };
    case Constants.showHidePregnancyModal:
      let isShowingPregModal = !state.pregnancyModalShowing;
      return { ...state, pregnancyModalShowing: isShowingPregModal };
    case Constants.addToPapStack:
      let currentPapStack = state.papStack;
      currentPapStack.unshift(action.payload);
      let newPapStack = currentPapStack;
      return { ...state, papStack: newPapStack };
    case Constants.addToPathologyStack:
      let currentPathStack = state.pathologyStack;
      currentPathStack.unshift(action.payload);
      let newPathStack = currentPathStack;
      return { ...state, pathologyStack: newPathStack };
    case Constants.addToScreenTypeArray:
      let currentScreenTypeArray = state.screenTypeArray;
      currentScreenTypeArray.unshift(action.payload);
      let newCurrentScreenTypeArray = currentScreenTypeArray;
      return { ...state, screenTypeArray: newCurrentScreenTypeArray };
    case Constants.removeFromScreenTypeArray:
      let currentScreenTypeArray2 = state.screenTypeArray;
      currentScreenTypeArray2.shift();
      let newCurrentScreenTypeArray2 = currentScreenTypeArray2;
      return { ...state, screenTypeArray: newCurrentScreenTypeArray2 };
    case Constants.clearAllPathology:
      return {
        ...state,
        pathologyResult: "",
        eccResult: "",
        marginResult: "",
        isExcision: false,
      };
    case Constants.shiftPapStack:
      let currentPapStack2 = state.papStack;
      currentPapStack2.shift();
      let newPapStack2 = currentPapStack2;
      return { ...state, papStack: newPapStack2 };
    case Constants.shiftPathStack:
      let currentPathStack2 = state.pathologyStack;
      currentPathStack2.shift();
      let newPathStack2 = currentPathStack2;
      return { ...state, pathologyStack: newPathStack2 };
    case Constants.setInitialState:
      let setInitial = initialState;
      return { ...setInitial };

    default:
      return state;
  }
};

export const PapAppContext = createContext();

export const AppContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PapAppContext.Provider value={{ state, dispatch }}>
      {children}
    </PapAppContext.Provider>
  );
};
