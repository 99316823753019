import React from "react";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports.js";
import ReactDOM from "react-dom";
import { App } from "./App";
import { AppContext } from "./AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

Amplify.configure(awsExports);

ReactDOM.render(
  <AppContext>
    <Router>
      <App />
    </Router>
  </AppContext>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// npm run-script buildnpm run-script build
