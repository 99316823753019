import React, { useState, useRef, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import KPLogoAnimation from "../Images/KPLogoAnimation";
import styled, { keyframes } from "styled-components";
import { PapAppContext } from "../AppContext";
import * as Constants from "../constants";
import LoaderButton from "./LoaderButton";

const SignUpScreen = () => {
  return <div>SignUpScreen</div>;
};

export default SignUpScreen;
