import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";

import * as Constants from "../../constants";
import styled from "styled-components";
import { StyledButton } from "../../Components/StyledButton";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`;

export const RecommendationTitle = styled.h1`
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  color: black;
  box-shadow: 0 3px 6px -6px black;
  background-color: #fafafa;
`;

export const RecommendationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1vh;
`;

export const RecommendationLogo = styled.div`
  width: 3vh;
  height: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Constants.KAISER_BLUE};
  border-radius: 50%;
`;

export const RecommendationLogoFunction = () => {
  return (
    <RecommendationLogo>
      <FontAwesomeIcon
        style={{ width: "1.5vh", height: "1.7vh", color: "white" }}
        icon={faNotesMedical}
      ></FontAwesomeIcon>
    </RecommendationLogo>
  );
};

export const RecommendationTitleSpan = styled.span`
  font-size: 2vh;
  align-self: center;
  padding: 1vh;
`;
export const FollowUpHeader = styled.h2`
  text-align: center;
  width: 90%;
  height: auto;
  color: black;
  background-color: ${Constants.LIGHT_GRAY};
`;

export const CategoryTitle = styled.div`
  margin-left: 1vw;
  margin-top: 1vh;
  width: 90%;
  height: auto;
  color: ${Constants.KAISER_BLUE};
  background-color: "white";
`;

export const SubmitButton = styled(StyledButton)`
  align-self: center;
  width: 15vh;
  height: 5vh;
  margin-top: 3vh;
  margin-bottom: 1vh;
  box-shadow: 0 3px 6px -6px black;
  background-color: ${Constants.KAISER_BLUE};
  color: #fafafa;
`;

export const Header = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "5%",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "5px",
      }}
    >
      {children}
    </div>
  );
};

export const GoHomeButton = () => {
  const style = {
    width: "12%",
    height: "90",
    padding: "2px",
    color: Constants.KAISER_BLUE,
    cursor: "pointer",
    textAlign: "center",
    marginRight: "5px",
    borderRadius: "3px",
    textDecoration: "none",
  };

  return (
    <Link style={style} to={Constants.MANAGEMENT}>
      <FontAwesomeIcon
        icon={faHome}
        style={{ width: "5vh", height: "5vh" }}
      ></FontAwesomeIcon>
    </Link>
  );
};
