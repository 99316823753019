import React from "react";
import styled from "styled-components";
import * as Constants from "../constants";

const StyledDiv = styled.div`
  width: 100%;
  diplay: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`;

export const InputContainer = ({ children }) => {
  return <StyledDiv id="inputContainer">{children}</StyledDiv>;
};
