import React, { useContext } from "react";
import { PapAppContext } from "../AppContext";
import styled from "styled-components";
import { createPortal } from "react-dom";
import * as Constants from "../constants";

export const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  margin-left: 10px;
  background: #e5e4e2;
`;

const StyledH1 = styled.h1`
  cursor: pointer;
`;

const StyledSeparator = styled.div`
  width: 50%;
  height: 1px;
  background-color: ${Constants.KAISER_BLUE};
`;

/////////////////////

export const AgeModal = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const onClick = (e) => {
    dispatch({ type: Constants.setAge, payload: e.target.id });

    dispatch({ type: Constants.showHideAgeModal });
    return false;
  };

  return state.ageModalShowing
    ? createPortal(
        <StyledDiv>
          <StyledH1 id={Constants.twentyOne_Four} onClick={(e) => onClick(e)}>
            {Constants.twentyOne_Four}
          </StyledH1>
          <StyledSeparator></StyledSeparator>
          <StyledH1 id={Constants.twentyFive_64} onClick={(e) => onClick(e)}>
            {Constants.twentyFive_64}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.sixtyFive} onClick={(e) => onClick(e)}>
            {Constants.sixtyFive}
          </StyledH1>
          <StyledSeparator></StyledSeparator>
        </StyledDiv>,
        document.getElementById("inputContainer")
      )
    : null;
};

/////////////////////////////////////////

export const HPVModal = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const onClick = (e) => {
    dispatch({ type: Constants.setHPVStatus, payload: e.target.id });

    dispatch({ type: Constants.showHideHPVModal });
    return false;
  };

  return state.hpvModalShowing
    ? createPortal(
        <StyledDiv>
          <StyledH1 id={Constants.HPV_NotIndicated} onClick={(e) => onClick(e)}>
            {Constants.HPV_NotIndicated}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.HPV_NEGATIVE} onClick={(e) => onClick(e)}>
            {Constants.HPV_NEGATIVE}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.HPV_POSITIVE} onClick={(e) => onClick(e)}>
            {Constants.HPV_POSITIVE}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1
            id={Constants.HPV_16_18_POSITIVE}
            onClick={(e) => onClick(e)}
          >
            {Constants.HPV_16_18_POSITIVE}
          </StyledH1>
          <StyledSeparator></StyledSeparator>
        </StyledDiv>,
        document.getElementById("inputContainer")
      )
    : null;
};

//////////////////////////////////////////

export const CytologyModal = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const onClick = (e) => {
    dispatch({ type: Constants.setCytologyResult, payload: e.target.id });

    dispatch({ type: Constants.showHideCytologyModal });
    return false;
  };

  return state.cytologyModalShowing
    ? createPortal(
        <StyledDiv>
          <StyledH1 id={Constants.NIEL} onClick={(e) => onClick(e)}>
            {Constants.NIEL}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.ASCUS} onClick={(e) => onClick(e)}>
            {Constants.ASCUS}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.LSIL} onClick={(e) => onClick(e)}>
            {Constants.LSIL}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.ASC_H} onClick={(e) => onClick(e)}>
            {Constants.ASC_H}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.HSIL} onClick={(e) => onClick(e)}>
            {Constants.HSIL}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.AGC_NOS} onClick={(e) => onClick(e)}>
            {Constants.AGC_NOS}
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id={Constants.AGC_NEO} onClick={(e) => onClick(e)}>
            {Constants.AGC_NEO}
          </StyledH1>
          <StyledSeparator></StyledSeparator>
        </StyledDiv>,
        document.getElementById("inputContainer")
      )
    : null;
};

//////////////////////////////////

export const PregnancyModal = () => {
  const { state, dispatch } = useContext(PapAppContext);

  const onClick = (e) => {
    let pregnantStatus = e.target.id === "Not Pregnant" ? false : true;
    dispatch({ type: Constants.setPregnancyStatus, payload: pregnantStatus });

    dispatch({ type: Constants.showHidePregnancyModal });
    return false;
  };

  return state.pregnancyModalShowing
    ? createPortal(
        <StyledDiv>
          <StyledH1 id="Not Pregnant" onClick={(e) => onClick(e)}>
            No
          </StyledH1>
          <StyledSeparator></StyledSeparator>

          <StyledH1 id="Pregnant" onClick={(e) => onClick(e)}>
            Yes
          </StyledH1>
          <StyledSeparator></StyledSeparator>
        </StyledDiv>,
        document.getElementById("inputContainer")
      )
    : null;
};
