/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:63aa7d8f-55ab-4481-9145-ad284e01c046",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HsSPW5Xcj",
    "aws_user_pools_web_client_id": "322hcf108ct0upllvp2ak4p07f",
    "oauth": {}
};


export default awsmobile;
