export const USER_HAS_AUTHENTICATED = "userHasAuthenticated";
export const FINISH_AUTHENTICATING = "finishAuthenticating";
export const START_AUTHENTICATING = "startAuthenticating";
export const SET_IS_LOADING = "setIsLoading";

export const KAISER_BLUE = "#006ba6";
export const LIGHT_GRAY = "#D3D3D3";

export const Management = "Management";
export const Screening = "Screening";
export const Algorithms = "Algorithms";

export const AGE = "Age";
export const PREGNANT = "Pregnant";

export const twentyOne_Four = "21-24";
export const twentyFive_64 = "25-65";
export const sixtyFive = "66+";

export const NIEL = "NIL";
export const ASCUS = "ASCUS";
export const LSIL = "LSIL";
export const ASC_H = "ASC-H";
export const HSIL = "HSIL";
export const HPV_POSITIVE = "Positive";
export const HPV_NEGATIVE = "Negative";
export const HPV_16_18_POSITIVE = "16/18 Positive";
export const HPV_NotIndicated = "Not Indicated";
export const AGC_NOS = "AGC-NOS";
export const AGC_NEO = "AGC-NEO";

export const CYTOLOGY_ARRAY = [
  NIEL,
  ASCUS,
  LSIL,
  ASC_H,
  HSIL,
  AGC_NOS,
  AGC_NEO,
];

export const HPV_ARRAY = [
  HPV_NotIndicated,
  HPV_NEGATIVE,
  HPV_POSITIVE,
  HPV_16_18_POSITIVE,
];

export const HomeScreen = "Home Screen";
export const Routine = "Routine";
export const Cytology_12 = "Cyto_12";
export const Colposcopy_Pregnant = "Colposcopy_Pregnant";
export const Colposcopy = "Colposcopy";
export const Cotest_12 = "Cotest_12";
export const Cotest_12_24 = "Cotest_12_24";
export const Colpo_Pap_6_For_24 = "Colpo_Pap_6_For_24";
export const Colpo_Cotest_6_Hyst = "Colpo_Cotest_6_Hyst";
export const Colpo_Cotest_6_Hyst_HEADER =
  "Cotest and ECC Q6 Months Until Hysterectomy";
export const Excision_Or_Colpo = "Excision_Or_Colpo";
export const Excision = "Excision";
export const Gyn_Onc = "Gyn Onc";
export const RepeatExcision_Vs_ECC_Cyto = "RepeatExcision_Vs_ECC_Cyto";
export const RepeatExcision_Vs_ECC_Cyto_Header =
  "Repeat Excisional Procedure vs Cytology and ECC in 4-6 Months";
export const RepeatExcision_Vs_Colpo_Cotest_Hyst =
  "RepeatExcision_Vs_Colpo_Cotest_Hyst";
export const RepeatExcision_Vs_Colpo_Cotest_Hyst_HEADER =
  "Repeat Excisional Procedure vs Cotest and Colposcopy Q6 Months Until Hysterectomy";
export const Cotest_12_24_OR_Excision = "Cotest_12_24_OR_Excision ";
export const ECC_Cyto = "ECC_Cyto";
export const Colpo_Preg_Low = "Colpo_Preg_Low";
export const Colpo_Preg_High = "Colpo_Preg_High";
export const Cyto_Colpo_6_24_OR_Excision =
  "Cytology and Colposcopy Q6 Months for 24 Months or Excision";
export const COLPO_PAP_6_24_HEADER =
  "Colposcopy and Pap Q6 months up to 24 months";
export const EXCISION_HEADER = "Excision";
export const COTEST_12_HEADER = "Cotest in 12 months";
export const COTEST_12_24_HEADER = "Cotest at 12 and 24 months";

export const setAge = "setAge";
export const showHideAgeModal = "showHideAgeModal";

export const setHPVStatus = "setHPVStatus";
export const showHideHPVModal = "showHideHPVModal";

export const showHideCytoColpo_6_24_OR_Excision =
  "showHideCytoColpo_6_24_OR_Excision";

export const showHideCotest_12_24_OR_Excision =
  "showHideCotest_12_24_OR_Excision";
export const showHideExcision_OR_Colpo = "showHideExcision_OR_Colpo";
export const showHideRepeatExcision_Vs_Colpo_Cotest_Hyst =
  "showHideRepeatExcision_Vs_Colpo_Cotest_Hyst";
export const showHideRepeatExcision_Vs_ECC_Cyto =
  "showHideRepeatExcision_Vs_ECC_Cyto";

export const setCytologyResult = "setCytologyResult";
export const showHideCytologyModal = "showHideCytologyModal";

export const setPregnancyStatus = "setPregnancyStatus";
export const showHidePregnancyModal = "showHidePregnancyModal";

export const setPathologyResult = "setPathologyResult";

export const setExcision = "setExcision";

export const setEccResult = "setEccResult";

export const setMarginResult = "setMarginResult";

export const addToPapStack = "addToPapStack";

export const addToPathologyStack = "addToPathologyStack";

export const clearAllPathology = "clearAllPathology";
export const clearCytologyResult = "clearCytologyResult";

export const NO_CIN = "No CIN";
export const CIN_1 = "CIN 1";
export const CIN_1_2 = "CIN 1/2";
export const CIN_2 = "CIN 2";
export const CIN_2_3 = "CIN 2/3";
export const CIN_3 = "CIN 3";
export const AIS = "AIS";
export const INVASIVE_CANCER = "Invasive Cancer";

export const PATHOLOGY_ARRAY = [NO_CIN, CIN_1, CIN_2, CIN_3, AIS];

export const BIOPSY = "Biopsy";
export const ECC = "ECC";
export const CYTOLOGY = "Cytology";
export const MARGIN = "Margin";
export const HPV = "HPV";

export const followUpResults = "Follow Up Results";
export const biopsyResult = "Biopsy Result";
export const cytologyResults = "Cytology Result";
export const hpvResults = "HPV Result";
export const eccResult = "ECC Result";
export const PATH = "path";
export const CYTO = "cyto";

//Header Titles

export const ROUTINE_SCREENING_HEADER = "Routine Screening";
export const CYTO_12_HEADER = "Cytology in 12 months";
export const COLPO_HEADER = "Colposcopy";
export const ECC_Cyto_HEADER = "Cytology and ECC in 4-6 months";
export const COLPO_PREGNANT_HEADER = "Colposcopy now or 6 weeks postpartum";
export const COLPO_PREG_HIGHGRADE_HEADER = "Colposcopy";
export const COLD_KNIFE_HEADER = "Cold Knife Cone Biopsy";
export const COTEST_ECC_HYST_HEADER =
  "Cotest and ECC Q6 months until Hysterectomy";

export const AGC_FOLLOWUP_HEADER = "AGC Follow Up";
export const Excision_Or_Colpo_HEADER = "Excision or Colposcopy";
export const INVASIVE_CANCER_HEADER = "Invasive Cancer";
export const GYN_ONC_HEADER = "Follow up with Gyn Oncology";
export const TREATMENT_FU_POSITIVE_HEADER = "Residual Disease Suspected";
export const PREGNANT_HIST_LOW_HEADER =
  "Repeat cytology and colposcopy 6 weeks post partum";
export const PREGNANT_HIST_HIGH_HEADER =
  "Repeat colposcopic impression and cytology every trimester";

export const MANAGEMENT = "/management";

export const shiftPapStack = "shiftPapStack";
export const shiftPathStack = "shiftPathStack";
export const setInitialState = "setInitialState";

export const addToScreenTypeArray = "addToScreenTypeArray";
export const removeFromScreenTypeArray = "removeFromScreenTypeArray";

export const buttonBoxInitialState = {
  NoCIN: false,
  CIN1: false,
  CIN2: false,
  CIN3: false,
  AIS: false,
  NIEL: false,
  ASCUS: false,
  LSIL: false,
  ASC_H: false,
  HSIL: false,
  AGC_NOS: false,
  AGC_NEO: false,
  HPV_Positive: false,
  HPV_Negative: false,
  HPV_16_18_Positive: false,
  HPV_NotIndicated: false,
};

export const ROUTE_PATHS_MANAGEMENT = {
  HomeScreen: `${MANAGEMENT}`,
  Colposcopy: `${MANAGEMENT}${Colposcopy.toLowerCase()}`,
  Colpo_Pap_6_For_24: `${MANAGEMENT}${Colpo_Pap_6_For_24.toLowerCase()}`,
  Cotest_12_24: `${MANAGEMENT}${Cotest_12_24.toLowerCase()}`,
  Cotest_12: `${MANAGEMENT}${Cotest_12.toLowerCase()}`,
  Cytology_12: `${MANAGEMENT}${Cytology_12.toLowerCase()}`,
  ECC_Cyto: `${MANAGEMENT}${ECC_Cyto.toLowerCase()}`,
  Excision: `${MANAGEMENT}${Excision.toLowerCase()}`,
  Gyn_Onc: `${MANAGEMENT}${Gyn_Onc.toLowerCase()}`,
  Routine: `${MANAGEMENT}${Routine.toLowerCase()}`,
  Cyto_Colpo_6_24_OR_Excision: `${MANAGEMENT}${Cyto_Colpo_6_24_OR_Excision.toLowerCase()}`,
  Cotest_12_24_OR_Excision: `${MANAGEMENT}${Cotest_12_24_OR_Excision.toLowerCase()}`,
  Excision_Or_Colpo: `${MANAGEMENT}${Excision_Or_Colpo.toLowerCase()}`,
  Colpo_Cotest_6_Hyst: `${MANAGEMENT}${Colpo_Cotest_6_Hyst}`,
  RepeatExcision_Vs_Colpo_Cotest_Hyst: `${MANAGEMENT}${RepeatExcision_Vs_Colpo_Cotest_Hyst.toLowerCase()}`,
  RepeatExcision_Vs_ECC_Cyto: `${MANAGEMENT}${RepeatExcision_Vs_ECC_Cyto.toLowerCase()}`,
  Colposcopy_Pregnant: `${MANAGEMENT}${Colposcopy_Pregnant}`,
  Colpo_Preg_Low: `${MANAGEMENT}${Colpo_Preg_Low}`,
  Colpo_Preg_High: `${MANAGEMENT}${Colpo_Preg_High}`,
};

export const goBackButtonStyle = {
  padding: "2px",
  color: KAISER_BLUE,
  cursor: "pointer",
  textAlign: "center",
  marginLeft: "5px",
  textDecoration: "none",
  borderRadius: "3px",
};
