import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100vw;
  height: 5vh;
  margin: 5px 5px 20px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: none;
  gap: 1em;
`;

export const Header = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};
