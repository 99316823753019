import * as Constants from "../constants";

export const GetInitialRecommendation = (
  cytologyResult,
  hpvStatus,
  age,
  isPregnant
) => {
  let recommendation;

  switch (cytologyResult) {
    case Constants.NIEL:
      recommendation = checkHpvStatus(hpvStatus, isPregnant);
      return recommendation;
    case Constants.ASCUS:
      recommendation = checkAgeThenHpvStatus_ASCUS(age, hpvStatus, isPregnant);
      return recommendation;
    case Constants.LSIL:
      recommendation = checkAgeThenHpvStatus_LSIL(age, hpvStatus, isPregnant);
      return recommendation;
    case Constants.ASC_H:
      switch (isPregnant) {
        case false:
          return Constants.Colposcopy;
        case true:
          return Constants.Colposcopy_Pregnant;
        default:
          return;
      }
    case Constants.HSIL:
      switch (isPregnant) {
        case false:
          return checkAge_HSIL(age);
        case true:
          return Constants.Colposcopy_Pregnant;
        default:
          return;
      }
    case Constants.AGC_NOS:
      switch (isPregnant) {
        case false:
          return Constants.Colposcopy;
        case true:
          return Constants.Colposcopy_Pregnant;
        default:
          return;
      }
    case Constants.AGC_NEO:
      switch (isPregnant) {
        case false:
          return Constants.Colposcopy;
        case true:
          return Constants.Colposcopy_Pregnant;
        default:
          return;
      }

    default:
      return;
  }
};
////////////////////////

const checkHpvStatus = (hpvStatus, isPregnant) => {
  switch (hpvStatus) {
    case Constants.HPV_NEGATIVE:
      return Constants.Routine;
    case Constants.HPV_NotIndicated:
      return Constants.Routine;
    case Constants.HPV_POSITIVE:
      return Constants.Cotest_12;
    case Constants.HPV_16_18_POSITIVE:
      switch (isPregnant) {
        case false:
          return Constants.Colposcopy;
        case true:
          return Constants.Colposcopy_Pregnant;
        default:
          return;
      }

    default:
      return;
  }
};

///////////////////////////////

const checkAgeThenHpvStatus_ASCUS = (age, hpvStatus, isPregnant) => {
  switch (age) {
    case Constants.twentyOne_Four:
      return Constants.Cytology_12;
    case Constants.twentyFive_64:
      switch (hpvStatus) {
        case Constants.HPV_NEGATIVE:
          return Constants.Routine;
        case Constants.HPV_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }
        case Constants.HPV_16_18_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        case Constants.HPV_NotIndicated:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        default:
          return;
      }
    case Constants.sixtyFive:
      switch (hpvStatus) {
        case Constants.HPV_NEGATIVE:
          return Constants.Routine;
        case Constants.HPV_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }
        case Constants.HPV_16_18_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        case Constants.HPV_NotIndicated:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        default:
          return;
      }
    default:
      return;
  }
};

////////////////////////////////

const checkAgeThenHpvStatus_LSIL = (age, hpvStatus, isPregnant) => {
  switch (age) {
    case Constants.twentyOne_Four:
      return Constants.Cytology_12;
    case Constants.twentyFive_64:
      switch (hpvStatus) {
        case Constants.HPV_NEGATIVE:
          return Constants.Cotest_12;
        case Constants.HPV_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }
        case Constants.HPV_16_18_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        case Constants.HPV_NotIndicated:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        default:
          return;
      }
    case Constants.sixtyFive:
      switch (hpvStatus) {
        case Constants.HPV_NEGATIVE:
          return Constants.Cotest_12;
        case Constants.HPV_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }
        case Constants.HPV_16_18_POSITIVE:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }

        case Constants.HPV_NotIndicated:
          switch (isPregnant) {
            case false:
              return Constants.Colposcopy;
            case true:
              return Constants.Colposcopy_Pregnant;
            default:
              return;
          }
        default:
          return;
      }
    default:
      return;
  }
};

////////////////////////////

const checkAge_HSIL = (age) => {
  switch (age) {
    case Constants.twentyOne_Four:
      return Constants.Colposcopy;
    case Constants.twentyFive_64:
      return Constants.Excision_Or_Colpo;
    case Constants.sixtyFive:
      return Constants.Excision_Or_Colpo;
    default:
      return;
  }
};
